import { ApolloClient, InMemoryCache, createHttpLink, makeVar, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const httpLink = createHttpLink({
    uri: 'https://suplo-backend.onrender.com/graphql/', // Asegúrate de que esta URL sea correcta
});

// Inicializa el reactive variable para el token
export const tokenVar = makeVar<string | null>(localStorage.getItem('access_token'));
export const selectedCompanyVar = makeVar<string | null>(null);


const isTokenExpired = (): boolean => {
    const refreshExpiresAt = localStorage.getItem('refresh_expires_at');
    if (!refreshExpiresAt) {
        return true;  // No hay fecha de expiración, asumimos que el token expiró
    }

    const expiresAtMilliseconds = parseInt(refreshExpiresAt, 10);
    if (isNaN(expiresAtMilliseconds)) {
        return true;  // Si la fecha guardada no es un número, asumimos que el token expiró
    }

    return Date.now() >= expiresAtMilliseconds;
};

const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshExpiresIn
    }
  }
`;

const VERIFY_TOKEN_MUTATION = gql`
  mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`;

const refreshAccessToken = async (refreshToken: string): Promise<string | null> => {
    try {
        const response = await client.mutate({
            mutation: REFRESH_TOKEN_MUTATION,
            variables: { refreshToken },  // Usar el nombre correcto del argumento
        });

        const refreshTokenData = response.data.refreshToken;

        if (refreshTokenData && refreshTokenData.token && refreshTokenData.refreshExpiresIn) {
            const accessToken = refreshTokenData.token;
            const refreshExpiresAt = Date.now() + refreshTokenData.refreshExpiresIn * 1000; // Convertir segundos a milisegundos
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('refresh_expires_at', refreshExpiresAt.toString());
            tokenVar(accessToken); // Actualiza el tokenVar de Apollo
            console.log('Nuevo access_token:', localStorage.getItem('access_token'));
            console.log('Nueva fecha de expiración del token de refresco:', new Date(refreshExpiresAt).toISOString());
            return accessToken;
        } else {
            throw new Error('No access token in response');
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_expires_at');
        tokenVar(null);
        return null;
    }
};

const verifyUserToken = async (): Promise<boolean> => {
    const token = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (!token) return false;
    try {
        await client.mutate({
            mutation: VERIFY_TOKEN_MUTATION,
            variables: { token },
        });
        return true;
    } catch (error) {
        console.error('Token verification failed:', error);
        if (refreshToken) {
            const newToken = await refreshAccessToken(refreshToken);
            if (newToken) {
                return true;
            }
        }
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_expires_at');
        localStorage.removeItem('refresh_token');
        tokenVar(null);
        return false;
    }
};

const authLink = setContext(async (request, { headers }) => {
    const operationName = request.operationName;
    const isExemptOperation = operationName === 'TokenAuth' || operationName === 'VerifyToken' || operationName === 'RefreshToken';

    if (isExemptOperation) {
        return { headers };
    }

    let token = tokenVar();
    if (token && isTokenExpired()) {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
            token = await refreshAccessToken(refreshToken);
        } else {
            // Si no hay refreshToken, eliminar el accessToken y limpiar el estado
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_expires_at');
            localStorage.removeItem('refresh_token');
            tokenVar(null);
            return { headers };
        }
    }

    return {
        headers: {
            ...headers,
            authorization: token ? `JWT ${token}` : "",
        },
    };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            if (err.extensions?.code === 'UNAUTHENTICATED') {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_expires_at');
                tokenVar(null);
            } else {
                console.error(`[GraphQL error]: ${err.message}`);
            }
        }
    }
    if (networkError) console.error(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    authToken: {
                        read() {
                            return tokenVar();
                        },
                    },
                },
            },
        },
    }),
});

export { client, verifyUserToken };
export default client;
