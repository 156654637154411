import React, { useState, useEffect } from 'react';
import {
    Box, Typography, Table, TableBody, TableContainer, TableHead, TableRow, Paper, TextField,
    InputAdornment, IconButton as MuiIconButton, Snackbar, Alert, Dialog, DialogActions,
    DialogContent, DialogTitle, Button, FormControl, InputLabel, Select, Chip
} from '@mui/material';
import { Visibility, VisibilityOff, Edit as EditIcon, Save as SaveIcon, Info as InfoIcon } from '@mui/icons-material';
import { useQuery, useReactiveVar, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { tokenVar, selectedCompanyVar } from './apolloClient';
import { GET_CREDENTIALS_BY_MASTER_ENTITY, GET_ALL_CREDENTIAL_PROVIDERS } from './graphql/queries';
import { UPDATE_CREDENTIAL, CREATE_CREDENTIAL } from './graphql/mutations';
import { Root, StyledTableCell, StyledTableHeadCell, NewIntegrationButton } from './styles/IntegrationsStyles';
import Loading from './Loading'; // Importar el componente Loading
import MenuComponent from './Menu';

const Integrations: React.FC = () => {
    const navigate = useNavigate();
    const token = useReactiveVar(tokenVar);
    const selectedCompany = useReactiveVar(selectedCompanyVar); // Use the reactive variable directly

    const [integrations, setIntegrations] = useState<any[]>([]);
    const [showPassword, setShowPassword] = useState(false);
    const [editableIntegration, setEditableIntegration] = useState<any | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [newIntegrationDialogOpen, setNewIntegrationDialogOpen] = useState(false);
    const [newIntegration, setNewIntegration] = useState({ provider: '', user: '', password: '' });

    const { loading: loadingIntegrations, data: integrationsData, refetch } = useQuery(GET_CREDENTIALS_BY_MASTER_ENTITY, {
        variables: { masterEntityId: selectedCompany ? parseInt(selectedCompany) : 0 },
        skip: !selectedCompany,
    });
    const { loading: loadingProviders, data: providersData } = useQuery(GET_ALL_CREDENTIAL_PROVIDERS);
    const [updateCredential] = useMutation(UPDATE_CREDENTIAL);
    const [createCredential] = useMutation(CREATE_CREDENTIAL);

    useEffect(() => {
        const loadData = async () => {
            if (selectedCompany) {
                const { data: newIntegrationsData } = await refetch({ masterEntityId: parseInt(selectedCompany) });
                if (newIntegrationsData && newIntegrationsData.credentialsByMasterEntity) {
                    setIntegrations(newIntegrationsData.credentialsByMasterEntity.map((integration: any) => ({
                        ...integration,
                        status: 'active', // Añadir estado por defecto
                        lastUpdated: new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString('es-ES', {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric',
                        }) + '\n09:00 AM' // Fecha de ayer a las 9 am en una nueva línea
                    })));
                }
            }
        };
        if (selectedCompany) {
            loadData();
        }
    }, [selectedCompany, refetch]);

    useEffect(() => {
        if (integrationsData && integrationsData.credentialsByMasterEntity) {
            setIntegrations(integrationsData.credentialsByMasterEntity.map((integration: any) => ({
                ...integration,
                status: 'active', // Añadir estado por defecto
                lastUpdated: new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                }) + '\n09:00 AM' // Fecha de ayer a las 9 am en una nueva línea
            })));
        }
    }, [integrationsData]);

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleEdit = (integration: any) => {
        setEditableIntegration({ ...integration });
    };

    const handleSave = async () => {
        if (editableIntegration) {
            const { id, user, password } = editableIntegration;
            await updateCredential({ variables: { credentialId: parseInt(id), user, password } });
            setEditableIntegration(null);
            refetch();
            setSnackbarMessage('Credencial actualizada exitosamente!');
            setSnackbarOpen(true);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditableIntegration((prev: any) => ({ ...prev, [name]: value }));
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleNewIntegrationDialogOpen = () => {
        setNewIntegrationDialogOpen(true);
    };

    const handleNewIntegrationDialogClose = () => {
        setNewIntegrationDialogOpen(false);
    };

    const handleNewIntegrationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewIntegration(prev => ({ ...prev, [name]: value }));
    };

    const handleCreateCredential = async () => {
        if (selectedCompany) {
            try {
                await createCredential({
                    variables: {
                        masterEntityId: parseInt(selectedCompany),
                        providerId: parseInt(newIntegration.provider),
                        user: newIntegration.user,
                        password: newIntegration.password,
                    }
                });
                setNewIntegrationDialogOpen(false);
                refetch();
                setSnackbarMessage('Integración creada exitosamente!');
                setSnackbarOpen(true);
            } catch (error: any) {
                setSnackbarMessage(error.message);
                setSnackbarOpen(true);
            }
        } else {
            console.error("No company selected");
        }
    };

    const handleSyncDetails = (integration: any) => {
        navigate(`/integrations/${integration.id}/sync-details`, { state: { integrationName: integration.provider.name } });
    };

    return (
        <Root>
            <MenuComponent refetch={refetch} />
            <Box p={3} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h4">Integraciones</Typography>
                <NewIntegrationButton variant="contained" color="primary" onClick={handleNewIntegrationDialogOpen}>
                    Crear Nueva Integración
                </NewIntegrationButton>
            </Box>
            <TableContainer component={Paper}>
                {(loadingIntegrations && integrations.length === 0) ? (
                    <Loading />
                ) : (
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableHeadCell>Nombre Integración</StyledTableHeadCell>
                                <StyledTableHeadCell>Usuario</StyledTableHeadCell>
                                <StyledTableHeadCell>Contraseña</StyledTableHeadCell>
                                <StyledTableHeadCell>Estado</StyledTableHeadCell>
                                <StyledTableHeadCell>Acciones</StyledTableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {integrations.map((integration: any) => (
                                <TableRow key={integration.id}>
                                    <StyledTableCell>{integration.provider.name}</StyledTableCell>
                                    <StyledTableCell>
                                        {editableIntegration?.id === integration.id ? (
                                            <TextField
                                                name="user"
                                                value={editableIntegration.user}
                                                onChange={handleChange}
                                            />
                                        ) : (
                                            integration.user
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {editableIntegration?.id === integration.id ? (
                                            <TextField
                                                name="password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={editableIntegration.password}
                                                onChange={handleChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <MuiIconButton onClick={handlePasswordVisibility}>
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </MuiIconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        ) : (
                                            '••••••••'
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Chip label="Activo" color="success" />
                                        <Typography variant="caption" display="block" gutterBottom>
                                            Última actualización:
                                            <br />
                                            {integration.lastUpdated.split('\n')[0]}
                                            <br />
                                            {integration.lastUpdated.split('\n')[1]}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <MuiIconButton onClick={() => handleEdit(integration)}>
                                            <EditIcon />
                                        </MuiIconButton>
                                        {editableIntegration?.id === integration.id && (
                                            <MuiIconButton onClick={handleSave}>
                                                <SaveIcon />
                                            </MuiIconButton>
                                        )}
                                        <MuiIconButton onClick={() => handleSyncDetails(integration)}>
                                            <InfoIcon />
                                        </MuiIconButton>
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Dialog open={newIntegrationDialogOpen} onClose={handleNewIntegrationDialogClose}>
                <DialogTitle>Crear Nueva Integración</DialogTitle>
                <DialogContent>
                    {loadingProviders ? (
                        <Loading />
                    ) : (
                        <>
                            <FormControl fullWidth margin="dense">
                                <InputLabel>Proveedor</InputLabel>
                                <Select
                                    native
                                    value={newIntegration.provider}
                                    onChange={(e) => setNewIntegration({ ...newIntegration, provider: e.target.value })}
                                >
                                    <option aria-label="None" value="" />
                                    {providersData?.allCredentialProviders.map((provider: any) => (
                                        <option key={provider.id} value={provider.id}>
                                            {provider.name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                margin="dense"
                                label="Usuario"
                                type="text"
                                fullWidth
                                name="user"
                                value={newIntegration.user}
                                onChange={handleNewIntegrationChange}
                            />
                            <TextField
                                margin="dense"
                                label="Contraseña"
                                type="password"
                                fullWidth
                                name="password"
                                value={newIntegration.password}
                                onChange={handleNewIntegrationChange}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNewIntegrationDialogClose}>Cancelar</Button>
                    <Button onClick={handleCreateCredential}>Crear</Button>
                </DialogActions>
            </Dialog>
        </Root>
    );
};

export default Integrations;
