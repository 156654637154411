import { styled } from '@mui/material/styles';
import { Typography, TableCell, Button, MenuItem } from '@mui/material';

export const Root = styled('div')(({ theme }) => ({
    flexGrow: 1,
}));

export const Logo = styled('img')(({ theme }) => ({
    height: 50,
    display: 'block',
    marginRight: theme.spacing(2),
}));

export const IntegrationsText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Helvetica, sans-serif',
    fontSize: 16,
    textTransform: 'capitalize',
    color: '#FFFFFF',
    marginLeft: theme.spacing(3),
    position: 'relative',
    cursor: 'pointer',
    '&.active::after': {
        content: '""',
        display: 'block',
        width: '100%',
        height: 2,
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        bottom: -5,
        left: 0,
    },
}));

export const CompanyButton = styled(Button)(({ theme }) => ({
    color: 'inherit',
    textTransform: 'capitalize',
    fontSize: 16,
    fontFamily: 'Helvetica, sans-serif',
    display: 'flex',
    alignItems: 'center',
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Helvetica, sans-serif',
    fontSize: 14,
}));

export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Helvetica, sans-serif',
    fontSize: 16,
    fontWeight: 'bold',
}));

export const CreateNewCompanyMenuItem = styled(MenuItem)(({ theme }) => ({
    fontWeight: 'bold',
    color: 'blue',
}));

export const NewIntegrationButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#466AFF',
    color: '#FFFFFF',
    '&:hover': {
        backgroundColor: '#3652cc',
    },
}));
