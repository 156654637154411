import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client, { verifyUserToken } from './apolloClient';
import Login from './Login';
import Integrations from './Integrations';
import Loading from './Loading';
import Documentation from './Documentation';
import SyncDetails from './SyncDetails';

const PrivateRoute = ({ element }: { element: JSX.Element }) => {
  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

  useEffect(() => {
    const verifyToken = async () => {
      const isValid = await verifyUserToken();
      setIsTokenValid(isValid);
    };

    verifyToken();
  }, []);

  if (isTokenValid === null) {
    return <Loading />;
  }

  return isTokenValid ? element : <Navigate to="/login" />;
};

const SyncDetailsWithProps = () => {
  const location = useLocation();
  const { integrationName } = location.state || { integrationName: '' };

  return <SyncDetails integrationName={integrationName} />;
};

const App: React.FC = () => {
  const token = localStorage.getItem('access_token');

  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/integrations" element={<PrivateRoute element={<Integrations />} />} />
          <Route path="/" element={<Navigate to={token ? "/integrations" : "/login"} />} />
          <Route path="/documentation" element={<PrivateRoute element={<Documentation />} />} />
          <Route path="/integrations/:id/sync-details" element={<PrivateRoute element={<SyncDetailsWithProps />} />} /> {/* Ruta para detalles de sincronización */}
        </Routes>
      </Router>
    </ApolloProvider>
  );
};

export default App;
