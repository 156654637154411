import React from 'react';
import {
    Box, Typography, Card, CardContent, Accordion, AccordionSummary, AccordionDetails,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Chip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuComponent from './Menu';

const Documentation: React.FC = () => {
    const dummyRefetch = () => {
        // No hacer nada
    };

    return (
        <Box>
            <MenuComponent refetch={dummyRefetch} />
            <Box p={3}>
                <Typography variant="h4" gutterBottom>Documentación de Tablas</Typography>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Tabla Sellouts</Typography>
                        <Chip label="Ventas" color="primary" style={{ marginLeft: '10px' }} />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Card variant="outlined" style={{ borderColor: '#1976d2', background: '#e3f2fd' }}>
                            <CardContent>
                                <Typography variant="body1" gutterBottom>
                                    Descripción: Contiene información de ventas realizadas.
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Atributos:
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ backgroundColor: '#1976d2', color: '#fff' }}>Atributo</TableCell>
                                                <TableCell style={{ backgroundColor: '#1976d2', color: '#fff' }}>Descripción</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>ID</TableCell>
                                                <TableCell>Identificador único.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Fecha</TableCell>
                                                <TableCell>Fecha de la venta.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Producto</TableCell>
                                                <TableCell>Identificador del producto vendido.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Cantidad</TableCell>
                                                <TableCell>Cantidad vendida.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Precio</TableCell>
                                                <TableCell>Precio de venta.</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box mt={2}>
                                    <Typography variant="body1">
                                        Ejemplo de Consulta:
                                    </Typography>
                                    <Box component="pre" bgcolor="#e0f7fa" p={2} borderRadius={4} mt={1}>
                                        SELECT * FROM Sellouts WHERE Fecha = '2023-01-01';
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Tabla Stocks</Typography>
                        <Chip label="Inventario" color="secondary" style={{ marginLeft: '10px' }} />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Card variant="outlined" style={{ borderColor: '#d32f2f', background: '#ffebee' }}>
                            <CardContent>
                                <Typography variant="body1" gutterBottom>
                                    Descripción: Contiene información del inventario disponible.
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Atributos:
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ backgroundColor: '#d32f2f', color: '#fff' }}>Atributo</TableCell>
                                                <TableCell style={{ backgroundColor: '#d32f2f', color: '#fff' }}>Descripción</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>ID</TableCell>
                                                <TableCell>Identificador único.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Producto</TableCell>
                                                <TableCell>Identificador del producto en inventario.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Cantidad</TableCell>
                                                <TableCell>Cantidad disponible en inventario.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Ubicación</TableCell>
                                                <TableCell>Ubicación del inventario.</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box mt={2}>
                                    <Typography variant="body1">
                                        Ejemplo de Consulta:
                                    </Typography>
                                    <Box component="pre" bgcolor="#ffccbc" p={2} borderRadius={4} mt={1}>
                                        SELECT * FROM Stocks WHERE Producto = '12345';
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </AccordionDetails>
                </Accordion>

            </Box>
        </Box>
    );
};

export default Documentation;
