import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box, Typography, IconButton, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper, TablePagination
} from '@mui/material';
import { ArrowBack as ArrowBackIcon, CheckCircle as CheckCircleIcon, Error as ErrorIcon } from '@mui/icons-material';
import MenuComponent from './Menu';
import { format, subDays } from 'date-fns';

interface SyncDetailsProps {
    integrationName: string;
}

const SyncDetails: React.FC<SyncDetailsProps> = ({ integrationName }) => {
    const dummyRefetch = () => {
        // No hacer nada
    };
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    // Generar datos de sincronización
    const syncData = [];
    for (let i = 0; i < 100; i++) {
        const date = subDays(new Date(), Math.floor(i / 2) + 1); // Fechas desde ayer hasta 50 días atrás, dos registros por día
        const status = Math.random() > 0.8 ? 'error' : 'success'; // 20% probabilidad de error
        const errorType = status === 'error' ? 'Clave incorrecta' : '';
        syncData.push({
            id: i + 1,
            integrationName,
            date: date,
            formattedDate: format(date, 'dd MMMM yyyy'),
            time: '08:30 AM',
            dataType: i % 2 === 0 ? 'Inventario' : 'Ventas',
            status,
            errorType,
        });
    }

    // Ordenar los datos por fecha (más reciente a más viejo) y tipo de datos (alfabético descendente)
    syncData.sort((a, b) => {
        if (b.date.getTime() !== a.date.getTime()) {
            return b.date.getTime() - a.date.getTime(); // Ordenar por fecha descendente
        }
        if (a.dataType < b.dataType) return 1;
        if (a.dataType > b.dataType) return -1;
        return 0;
    });

    const [page, setPage] = useState(0);
    const rowsPerPage = 10;

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    return (
        <Box>
            <MenuComponent refetch={dummyRefetch} />
            <Box p={3}>
                <Box display="flex" alignItems="center">
                    <IconButton onClick={() => navigate(-1)} color="primary">
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h4" ml={1}>
                        Detalles de Sincronización - {integrationName}
                    </Typography>
                </Box>
                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre Integración</TableCell>
                                <TableCell>Fecha y Hora Carga</TableCell>
                                <TableCell>Tipo de Datos</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Error</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {syncData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sync) => (
                                <TableRow key={sync.id}>
                                    <TableCell>{sync.integrationName}</TableCell>
                                    <TableCell>{sync.formattedDate} {sync.time}</TableCell>
                                    <TableCell>{sync.dataType}</TableCell>
                                    <TableCell>
                                        {sync.status === 'success' ? (
                                            <CheckCircleIcon color="success" />
                                        ) : (
                                            <ErrorIcon color="error" />
                                        )}
                                    </TableCell>
                                    <TableCell>{sync.errorType}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={syncData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </TableContainer>
            </Box>
        </Box>
    );
};

export default SyncDetails;
