import React, { useState } from 'react';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import logo from './assets/logo.png';
import { tokenVar } from './apolloClient';
import { LoginContainer, LoginForm, LogoBox, GlobalStyle } from './styles/LoginStyles';
import { GET_CURRENT_USER } from './graphql/queries';
import useSelectedCompany from './hooks/useSelectedCompany';

const LOGIN_MUTATION = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      refreshToken
      refreshExpiresIn
    }
  }
`;

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const client = useApolloClient();
    const { selectCompany } = useSelectedCompany();

    const [login, { loading: loginLoading, error: loginError }] = useMutation(LOGIN_MUTATION, {
        onCompleted: async (data) => {
            if (data?.tokenAuth?.token && data?.tokenAuth?.refreshToken && data?.tokenAuth?.refreshExpiresIn) {
                const accessToken = data.tokenAuth.token;
                const refreshToken = data.tokenAuth.refreshToken;
                const refreshExpiresAt = Date.now() + data.tokenAuth.refreshExpiresIn * 1000;
                localStorage.setItem('access_token', accessToken);
                localStorage.setItem('refresh_token', refreshToken);
                localStorage.setItem('refresh_expires_at', refreshExpiresAt.toString());
                tokenVar(accessToken);

                // Obtener la empresa seleccionada
                try {
                    const { data: userData } = await client.query({ query: GET_CURRENT_USER });
                    if (userData?.me?.selected_company) {
                        selectCompany(userData.me.selected_company.id.toString());
                    }
                } catch (error) {
                    console.error('Error al obtener la empresa seleccionada:', error);
                }

                navigate('/integrations');
            } else {
                console.error('Tokens no recibidos');
            }
        },
        onError: (error) => {
            console.error('Error en login:', error);
        }
    });

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await login({ variables: { email, password } });
        } catch (error) {
            console.error('Error en handleSubmit:', error);
        }
    };

    return (
        <>
            <GlobalStyle />
            <LoginContainer>
                <LogoBox>
                    <img src={logo} alt="Suplo Logo" style={{ height: 100 }} />
                </LogoBox>
                <LoginForm onSubmit={handleSubmit}>
                    <Box mb={3}>
                        <Typography variant="h5" component="h1" align="center">
                            Entra a Suplo
                        </Typography>
                    </Box>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Box mt={2}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={loginLoading}
                            sx={{ backgroundColor: '#466AFF', color: '#FFFFFF', textTransform: 'none' }}
                        >
                            {loginLoading ? <CircularProgress size={24} /> : 'Iniciar Sesión'}
                        </Button>
                    </Box>
                    {loginError && (
                        <Box mt={2}>
                            <Typography color="error" align="center">
                                Error logging in: {loginError.message}
                            </Typography>
                        </Box>
                    )}
                </LoginForm>
            </LoginContainer >
        </>
    );
};

export default Login;
