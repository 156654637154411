import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Box, IconButton, Menu, MenuItem, Divider, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon, Notifications as NotificationsIcon, AccountCircle, Logout as LogoutIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from './assets/logo.png';
import useSelectedCompany from './hooks/useSelectedCompany';
import { useReactiveVar, useQuery, useMutation } from '@apollo/client';
import { tokenVar } from './apolloClient';
import { GET_MASTER_ENTITIES } from './graphql/queries';
import { CREATE_MASTER_ENTITY } from './graphql/mutations';
import { CompanyButton, CreateNewCompanyMenuItem, IntegrationsText, Logo } from './styles/IntegrationsStyles';

const MenuComponent: React.FC<{ refetch: () => void }> = ({ refetch }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = useReactiveVar(tokenVar);
    const { selectedCompany, loadingUser, selectCompany } = useSelectedCompany();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [newCompanyName, setNewCompanyName] = useState('');
    const [newCompanyRut, setNewCompanyRut] = useState('');

    const { loading: loadingCompanies, data: companiesData, error: companiesError, refetch: refetchCompanies } = useQuery(GET_MASTER_ENTITIES, {
        notifyOnNetworkStatusChange: true,
    });
    const [createMasterEntity] = useMutation(CREATE_MASTER_ENTITY);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = async (company?: string) => {
        setAnchorEl(null);
        if (company) {
            await selectCompany(company);
            await refetch(); // Asegurarse de que refetch se llame después de que selectCompany haya terminado
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        tokenVar(null);
        navigate('/login');
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleCreateCompany = async () => {
        const { data } = await createMasterEntity({ variables: { name: newCompanyName, rut: newCompanyRut } });
        if (data) {
            const newCompanyId = data.createMasterEntity.masterEntity.id.toString();
            await selectCompany(newCompanyId);
            setDialogOpen(false);
            await refetchCompanies(); // Refetch la lista de empresas después de crear una nueva
            await refetch(); // Asegurarse de que refetch se llame después de que selectCompany haya terminado
        }
    };

    if (companiesError) return <div>Error loading companies: {companiesError.message}</div>;

    const isIntegrationsActive = location.pathname === '/integrations';
    const isDocumentationActive = location.pathname === '/documentation';

    return (
        <>
            <AppBar position="static" sx={{ backgroundColor: '#466AFF' }}>
                <Toolbar>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Logo src={logo} alt="Suplo Logo" />
                        <IntegrationsText
                            onClick={() => navigate('/integrations')}
                            className={isIntegrationsActive ? 'active' : ''}
                        >
                            Integraciones
                        </IntegrationsText>
                        <IntegrationsText
                            onClick={() => navigate('/documentation')}
                            className={isDocumentationActive ? 'active' : ''}
                            sx={{ marginLeft: 2 }}
                        >
                            Documentación
                        </IntegrationsText>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <CompanyButton onClick={handleMenuClick}>
                        {loadingUser || !companiesData
                            ? 'Cargando...'
                            : selectedCompany
                                ? companiesData.allMasterEntities.find((company: any) => company.id.toString() === selectedCompany)?.name
                                : 'Seleccionar Empresa'}
                        <ArrowDropDownIcon />
                    </CompanyButton>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                        {companiesData?.allMasterEntities.map((company: any) => (
                            <MenuItem
                                key={company.id}
                                selected={company.id.toString() === selectedCompany}
                                onClick={() => handleMenuClose(company.id.toString())}
                            >
                                {company.name}
                            </MenuItem>
                        ))}
                        <Divider sx={{ my: 1 }} />
                        <CreateNewCompanyMenuItem onClick={handleDialogOpen}>
                            Crear Nueva Empresa
                        </CreateNewCompanyMenuItem>
                    </Menu>
                    <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                        <IconButton color="inherit">
                            <NotificationsIcon />
                        </IconButton>
                        <IconButton aria-label="account of current user" aria-haspopup="true" color="inherit">
                            <AccountCircle />
                        </IconButton>
                        <IconButton edge="end" color="inherit" onClick={handleLogout}>
                            <LogoutIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Crear Nueva Empresa</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nombre de la Empresa"
                        type="text"
                        fullWidth
                        value={newCompanyName}
                        onChange={(e) => setNewCompanyName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="RUT Chileno"
                        type="text"
                        fullWidth
                        value={newCompanyRut}
                        onChange={(e) => setNewCompanyRut(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancelar</Button>
                    <Button onClick={handleCreateCompany}>Crear</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MenuComponent;
