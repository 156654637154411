import { gql } from '@apollo/client';

export const UPDATE_CREDENTIAL = gql`
  mutation UpdateCredential($credentialId: Int!, $user: String, $password: String) {
    updateCredential(credentialId: $credentialId, user: $user, password: $password) {
      __typename
    }
  }
`;

export const UPDATE_SELECTED_COMPANY = gql`
    mutation UpdateSelectedCompany($companyId: Int!) {
        updateSelectedCompany(companyId: $companyId) {
            user {
                id
                selectedCompany {
                    id
                    name
                }
            }
        }
    } 
`;

export const CREATE_MASTER_ENTITY = gql`
    mutation CreateMasterEntity($name: String!, $rut: String!) {
        createMasterEntity(name: $name, rut: $rut) {
            masterEntity {
                id
                name
                rut
            }
        }
    }
`;

export const CREATE_CREDENTIAL = gql`
  mutation CreateCredential($masterEntityId: Int!, $providerId: Int!, $user: String!, $password: String!) {
    createCredential(masterEntityId: $masterEntityId, providerId: $providerId, user: $user, password: $password) {
      credential {
        id
        masterEntity {
          id
          name
        }
        provider {
          id
          name
        }
        user
        password
      }
    }
  }
`;

