import { gql } from '@apollo/client';

export const GET_MASTER_ENTITIES = gql`
  query {
    allMasterEntities {
      id
      name
      rut
      users {
        id
      }
    }
  }
`;

export const GET_CREDENTIALS_BY_MASTER_ENTITY = gql`
  query($masterEntityId: Int!) {
    credentialsByMasterEntity(masterEntityId: $masterEntityId) {
      id
      user
      password
      provider {
        name
        providerType {
          name
        }
      }
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query {
    me {
      id
      selectedCompany {
        id
        name
      }
    }
  }
`;

export const GET_ALL_CREDENTIAL_PROVIDERS = gql`
    query GetAllCredentialProviders {
        allCredentialProviders {
            id
            name
        }
    }
`;
