import { styled } from '@mui/material/styles';
import { Container, Box } from '@mui/material';

export const LoginContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    width: '100vw',
    backgroundColor: '#466AFF', // Fondo azul
    margin: 0,
    padding: 0,
}));

export const LoginForm = styled('form')(({ theme }) => ({
    width: '100%',
    maxWidth: 400,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    backgroundColor: '#fff', // Fondo blanco
}));

export const LogoBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));

export const GlobalStyle = styled('div')`
    html, body, #root {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
`;
