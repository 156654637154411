import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_CURRENT_USER } from '../graphql/queries';
import { UPDATE_SELECTED_COMPANY } from '../graphql/mutations';
import { useReactiveVar } from '@apollo/client';
import { selectedCompanyVar } from '../apolloClient';

const useSelectedCompany = () => {
    const selectedCompany = useReactiveVar(selectedCompanyVar);
    const { data, loading: loadingUser, refetch: refetchUser } = useQuery(GET_CURRENT_USER, {
        onCompleted: (data) => {
            if (data?.me?.selectedCompany) {
                selectedCompanyVar(data.me.selectedCompany.id.toString());
            }
        }
    });

    const [updateSelectedCompany] = useMutation(UPDATE_SELECTED_COMPANY, {
        onCompleted: async () => {
            const refetchResult = await refetchUser();
            console.log('Refetch user result:', refetchResult);
        }
    });

    const selectCompany = async (companyId: string): Promise<void> => {
        try {
            console.log('Updating company to:', companyId);
            await updateSelectedCompany({ variables: { companyId: parseInt(companyId, 10) } });
            selectedCompanyVar(companyId);
            console.log('Company updated to:', companyId);
        } catch (error) {
            console.error("Error selecting company:", error);
        }
    };

    useEffect(() => {
        console.log('Selected company updated to:', selectedCompany);
    }, [selectedCompany]);

    return {
        selectedCompany,
        loadingUser,
        selectCompany
    };
};

export default useSelectedCompany;